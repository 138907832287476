<template>
  <div>
    <b-card v-if="fetchTagTypes" no-body>
      <b-table
        ref="refUsersTagsListTable"
        class="position-relative table-white-space mb-0"
        :items="fetchTagTypes"
        responsive
        :fields="tableColumns"
        primary-key="index"
        :sort-by.sync="sortBy"
        show-empty
        empty-text="No matching records found"
        :sort-desc.sync="isSortDirDesc"
      >
        <template #cell(index)="data">
          {{ perPage * (currentPage - 1) + data.index + 1 }}
        </template>
        <template #cell(name)="data">
          <b-link
            class="text-white cursor-pointer"
            @click="showUsersTagEditModal(data.item.id)"
          >
            {{ data.item.name }}
          </b-link>
        </template>
        <template #cell(action)="data">
          <b-link
            v-if="$can('view', 'tagtypedetail')"
            class="font-weight-bold text-nowrap"
            @click="
              showUsersTagDetailModal(data.item.id, {
                created_at: formatDateTime(data.item.created_at).replace(
                  '.000000Z',
                  ''
                ),
                updated_at: formatDateTime(data.item.updated_at).replace(
                  '.000000Z',
                  ''
                ),
              })
            "
          >
            {{ $t("Detail") }}
          </b-link>
          <span class="px-1">|</span>
          <b-link
            v-if="$can('view', 'tagtypeedit')"
            class="font-weight-bold text-nowrap"
            @click="showUsersTagEditModal(data.item.id)"
          >
            {{ $t("Edit") }}
          </b-link>
        </template>

        <template #cell(tags)="data">
          <b-badge
            v-for="tag in data.item.tags"
            :key="tag.id"
            variant="primary"
            class="mr-1"
            :style="`background-color: ${tag.color} !important;`"
          >
            {{ tag.name }}
          </b-badge>
        </template>

        <template #cell(created_at)="data">
          {{
            data.item.created_at
              ? formatDateTime(data.item.created_at).replace(".000000Z", "")
              : ""
          }}
        </template>

        <template #cell(updated_at)="data">
          {{
            data.item.updated_at
              ? formatDateTime(data.item.updated_at).replace(".000000Z", "")
              : ""
          }}
        </template>
      </b-table>
      <div class="p-2">
        <b-row>
          <b-col
            cols="12"
            sm="6"
            class="
              d-flex
              align-items-center
              justify-content-center justify-content-sm-start
            "
          >
            <span class="text-muted"
              >Showing {{ dataMeta.from }} to {{ dataMeta.to }} of
              {{ dataMeta.of }} entries</span
            >
          </b-col>
          <!-- Pagination -->
          <b-col
            cols="12"
            sm="6"
            class="
              d-flex
              align-items-center
              justify-content-center justify-content-sm-end
            "
          >
            <b-pagination
              v-model="currentPage"
              :total-rows="totalTags"
              :per-page="perPage"
              first-number
              last-number
              class="mb-0 mt-1 mt-sm-0"
              prev-class="prev-item"
              next-class="next-item"
            >
              <template #prev-text>
                <feather-icon icon="ChevronLeftIcon" size="18" />
              </template>
              <template #next-text>
                <feather-icon icon="ChevronRightIcon" size="18" />
              </template>
            </b-pagination>
          </b-col>
        </b-row>
      </div>
    </b-card>
    <!-- Users tag detail modal -->
    <users-tag-detail-modal :tag-id="tagId" :date-time.sync="dateTime" />
    <!-- Users tag edit modal -->
    <users-tag-edit-modal :tag-id.sync="tagId" @refetch-data="refetchData" />
  </div>
</template>

<script>
import {
  BButton,
  BModal,
  VBModal,
  BCard,
  BRow,
  BCol,
  BFormInput,
  BTable,
  BTr,
  BTh,
  BMedia,
  BAvatar,
  BLink,
  BBadge,
  BDropdown,
  BDropdownItem,
  BPagination,
} from "bootstrap-vue";
import Ripple from "vue-ripple-directive";
import vSelect from "vue-select";
import { ref, watch, computed, onUnmounted } from "@vue/composition-api";
import { formatDateTime } from "@core/utils/filter";
// import UsersTagDetailModal from './UsersTagDetailModal.vue'
import store from "@/store";
import useUsersTag from "./useUsersTag";
import tagStoreModule from "./tagStoreModule";
import i18n from "@/libs/i18n";

export default {
  components: {
    UsersTagDetailModal: () => import("./UsersTagDetailModal.vue"),
    UsersTagEditModal: () => import("./UsersTagEditModal.vue"),
    BButton,
    BModal,
    BCard,
    BRow,
    BCol,
    BFormInput,
    BTable,
    BTr,
    BTh,
    BMedia,
    BAvatar,
    BLink,
    BBadge,
    BDropdown,
    BDropdownItem,
    BPagination,
    vSelect,
  },
  directives: {
    "b-modal": VBModal,
    Ripple,
  },
  props: {},
  data() {
    return {
      tagId: 0,
      dateTime: null,
    };
  },
  methods: {
    showUsersTagDetailModal(id, datetime) {
      this.tagId = id;
      this.dateTime = datetime;
      this.$bvModal.show("modal-users-tag-detail");
    },

    showUsersTagEditModal(id) {
      this.tagId = id;
      this.$bvModal.show("modal-users-tag-edit");
    },
  },
  setup() {
    const USER_TAG = "payment-deposit";

    // Register module
    if (!store.hasModule("app-user-tag"))
      store.registerModule("app-user-tag", tagStoreModule);
    // UnRegister on leave
    onUnmounted(() => {
      if (store.hasModule(USER_TAG)) store.unregisterModule(USER_TAG);
    });

    const { usersTags } = useUsersTag();

    const perPage = ref(20);
    const totalTags = ref(0);
    const currentPage = ref(1);
    const perPageOptions = [10, 25, 50, 100];
    const sortBy = ref("id");
    const isSortDirDesc = ref(true);

    const refUsersTagsListTable = ref(null);

    const tableColumns = [
      { key: "index", label: i18n.t("#") },
      { key: "name", label: i18n.t("Tag type") },
      { key: "tags", label: i18n.t("Tags") },
      { key: "description", label: i18n.t("Description") },
      { key: "created_at", label: i18n.t("Created at") },
      { key: "updated_at", label: i18n.t("Updated at") },
      { key: "action", label: i18n.t("Actions") },
    ];

    const dataMeta = computed(() => {
      const localItemsCount = refUsersTagsListTable.value
        ? refUsersTagsListTable.value.localItems.length
        : 0;
      return {
        from:
          perPage.value * (currentPage.value - 1) + (localItemsCount ? 1 : 0),
        to: perPage.value * (currentPage.value - 1) + localItemsCount,
        of: totalTags.value,
      };
    });

    const refetchData = () => {
      refUsersTagsListTable.value.refresh();
    };

    watch([currentPage], () => {
      refetchData();
    });

    const fetchTagTypes = (ctx, callback) => {
      store
        .dispatch("app-user-tag/fetchTagTypes", {
          page: currentPage.value,
        })
        .then((response) => {
          const { tagTypes, total, pageSize } = response;
          callback(tagTypes);
          totalTags.value = total;
          perPage.value = pageSize;
        });
    };

    return {
      tableColumns,
      perPage,
      currentPage,
      totalTags,
      dataMeta,
      perPageOptions,
      sortBy,
      isSortDirDesc,
      refUsersTagsListTable,

      formatDateTime,

      usersTags,
      fetchTagTypes,
      refetchData,
    };
  },
};
</script>
